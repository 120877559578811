import classNames from "classnames";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/GlobalContext.js";
import { formattedNumber } from "../../utils/formatNumber.js";
import TimerComponent from "../../utils/TimerComponent.js";
import LockStatusComponent from "../../utils/LockStatusComponent.js";
import { useAccount } from "wagmi";

const StakeDetails = ({ plan, plans, setPlan }) => {
  const { address } = useAccount();
  const { blockChainData } = useContext(GlobalContext);

  const planDetails = {
    0: {
      rewardBalance: blockChainData.RewardBalance.plan0,
      stakedTime: blockChainData.StakedTime.plan0,
      period: 2592000,
    },
    1: {
      rewardBalance: blockChainData.RewardBalance.plan1,
      stakedTime: blockChainData.StakedTime.plan1,
      period: 5184000,
    },
    2: {
      rewardBalance: blockChainData.RewardBalance.plan2,
      stakedTime: blockChainData.StakedTime.plan2,
      period: 7776000,
    },
    3: {
      rewardBalance: blockChainData.RewardBalance.plan3,
      stakedTime: blockChainData.StakedTime.plan3,
      period: 15552000,
    },
    4: {
      rewardBalance: blockChainData.RewardBalance.plan4,
      stakedTime: blockChainData.StakedTime.plan4,
      period: 31536000,
    },
  };

  const getRewardsEarned = () => {
    const reward = planDetails[plan.plan]?.rewardBalance;
    return reward ? formattedNumber(reward) : 0.0;
  };

  const getRewardTime = () => {
    const stakedTime = planDetails[plan.plan]?.stakedTime;
    const period = planDetails[plan.plan]?.period;
    return stakedTime ? (
      <TimerComponent endDate={BigInt(stakedTime).toString()} period={period} />
    ) : (
      <div className='px-4 text-center py-2 border-1 border-vyellow border rounded-md border-opacity-40 font-bold truncate'>
        😞 Nothing Staked
      </div>
    );
  };

  const getLockStatus = () => {
    const stakedTime = planDetails[plan.plan]?.stakedTime;
    const period = planDetails[plan.plan]?.period;

    return stakedTime ? (
      <LockStatusComponent
        endDate={BigInt(stakedTime).toString()}
        period={period}
      />
    ) : (
      <div className='px-4 text-center py-2 border-1 border-vyellow border rounded-md rounded-md border-opacity-40 font-bold truncate'>
        Status:{" "}
        <span className='text-left font-bold text-vyellow text-sm'>
          🔓 No Lock
        </span>
      </div>
    );
  };

  useEffect(() => {
    setPlan(plans[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockChainData]);

  return (
    <>
      <p className='text-left  text-vyellow my-7 text-md'>
        {" "}
        <span className='bg-vyellow rounded-full mr-2 w-5  text-xs font-bold h-5 text-black p-1'>
          01
        </span>
        How many days:
      </p>
      <div className='pb-4 flex items-center justify-between space-x-4 md:space-x-8'>
        {plans.map((planOption, index) => (
          <button
            key={index}
            className={`uppercase truncate text-sm  md:text-md rounded-md w-full text-lg font-medium px-1 md:px-3 py-2 md:py-3 ${
              plan.plan === index
                ? "bg-vyellow  border-1 border border-vyellow text-black"
                : "bg-vgray border-1 border border-opacity-30 border-vyellow"
            } `} // Assuming text should be white for visibility
            onClick={() => setPlan(planOption)}
          >
            {planOption.duration.split(" ")[0]}
          </button>
        ))}
      </div>
      <div className='my-4 flex flex-col gap-8  justify-between'>
        {" "}
        <div className='flex md:flex-row flex-col gap-2 justify-around w-full'>
          <p className='text-left font-light text-sm'>
            Lock period:{" "}
            <span className='text-vyellow font-bold'>{plan.duration}</span>
          </p>
          <p className='text-left font-light text-sm'>
            Re-locks on restake:{" "}
            <span className='text-vyellow font-bold'>Yes</span>
          </p>{" "}
          <p className='text-left  text-sm'>
            Rewards/Day:{" "}
            <span className='text-vyellow  text-sm font-bold'>
              {address ? getRewardsEarned() : 0.0}
            </span>
          </p>
        </div>
        <div className='flex justify-around md:flex-row flex-col gap-4 items-center w-full'>
          <p className='text-left font-bold text-sm'>
            {address ? getLockStatus() : null}
          </p>
          <p className='text-left font-bold text-sm text-vyellow'>
            {address ? getRewardTime() : null}
          </p>{" "}
        </div>
      </div>
    </>
  );
};

export default StakeDetails;
