import React, { useState } from "react";
import { ChevronDown } from "lucide-react";
import { Slot } from "@radix-ui/react-slot";

import { useLocation } from "react-router-dom";
type SidebarSubItemType = {
  title: string;
  url: string;
};

export type SidebarItemType = {
  submenu: any;
  title: string;
  url?: string;
  type?: "external";
  subItems?: SidebarSubItemType[];
};

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  active?: boolean;
}

const SidebarItemLink = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ asChild = false, active, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    const classNames = `sidebar-item-link ${active ? "active" : ""} ${
      props.className || ""
    }`;

    return <Comp className={classNames} ref={ref} {...props} />;
  }
);

SidebarItemLink.displayName = "SidebarItemLink";

function SidebarItem({ item }: { item: SidebarItemType }) {
  const myStyle = {
    color: "#dedf1b", // text-white
    fontSize: "13px", // text-sm, adjust according to your design system
    justifyContent: "flex-start", // justify-start

    marginLeft: "1.3rem", // ml-2

    gap: "6rem", // gap-24, adjust as per your spacing scale
    fontWeight: "normal", // font-normal

    paddingLeft: "3rem", // pl-11
    transition: "background-color 0.3s ease", // hover transition effects, if needed
  };
  const pathname = useLocation();
  const [active, setActive] = useState(
    item.submenu?.some((subItem: any) => subItem.url === pathname)
  );

  if (item.submenu) {
    return (
      <div>
        <SidebarItemLink
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={() => setActive(!active)}
        >
          {item?.title === "Exchange" ? (
            <a
              href={item.url}
              style={{ backgroundColor: "#131313", color: "#ADADAD" }}
            >
              <span>{item.title} </span>
              <span style={{ fontSize: "8px" }}>Coming Soon </span>
            </a>
          ) : (
            <a href={item.url}>
              <span>{item.title}</span>
            </a>
          )}{" "}
          {item.submenu && item.submenu.length > 0 && (
            <ChevronDown
              size={14}
              className={`chevron-down text-vyellow ${active ? "active" : ""}`}
            />
          )}
        </SidebarItemLink>

        {active && (
          <div
            style={{
              overflow: "hidden",
              marginTop: "calc(0.25rem * calc(1 - var(--space-y-reverse)))",
              marginBottom: "calc(0.25rem * var(--space-y-reverse))",
            }}
          >
            {item.submenu.map((subItem: any, subKey: any) => (
              <SidebarItemLink
                active={subItem.url === pathname}
                key={subKey}
                asChild
              >
                {subItem?.title === "Farming" ? (
                  <a href={subItem.url} style={myStyle}>
                    <span
                      style={{ backgroundColor: "#131313", color: "#ADADAD" }}
                    >
                      {subItem.title}{" "}
                      <span style={{ fontSize: "8px" }}>Coming Soon </span>
                    </span>
                  </a>
                ) : (
                  <a href={subItem.url} style={myStyle}>
                    {subItem.title}
                  </a>
                )}
              </SidebarItemLink>
            ))}
          </div>
        )}
      </div>
    );
  } else if (item.type === "external") {
    return (
      <SidebarItemLink asChild>
        {item?.title === "Exchange" ? (
          <a href={item.url} style={{ color: "#ADADAD" }}>
            <span>{item.title} Coming Soon</span>
          </a>
        ) : (
          <a href={item.url}>
            <span>{item.title}</span>
          </a>
        )}
      </SidebarItemLink>
    );
  } else if (item.url) {
    return (
      <SidebarItemLink
        asChild
        //@ts-ignore
        active={item.url === pathname}
      >
        {item?.title === "Exchange" ? (
          <a href={item.url} style={{ color: "#ADADAD" }}>
            <span>{item.title} Coming Soon</span>
          </a>
        ) : (
          <a href={item.url}>
            <span>{item.title}</span>
          </a>
        )}
      </SidebarItemLink>
    );
  }
  return null;
}
export default SidebarItem;
