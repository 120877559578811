"use client";
import React from "react";

import ToggleSidebarButton from "./ToggleSidebarButton.tsx";
import Nav from "./Nav.tsx";
import { useState } from "react";
import Sidebar from "./sidebar/Sidebar.tsx";

import MyWalletConnect from "./ConnectWallet.js";
export default function Navbar() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <div className='bg-black'>
      {isSidebarOpen && <Sidebar />}

      <header className='  flex items-center  inset-0 z-30 px-0 py-3 md:py-5 md:px-0 justify-between relative  '>
        <div className='flex justify-start'>
          <ToggleSidebarButton
            toggleSidebar={toggleSidebar}
            isSidebarOpen={isSidebarOpen}
            className='toggle-sidebar-button mx-4'
          />
          <a href='https://versx.io/' className='logo-link px-auto md:px-6   '>
            <img
              src='https://images.versx.io/public/logo.svg'
              className='w-24 md:w-32 h-auto my-auto'
            />
          </a>
        </div>
        <div className='hidden md:flex'>
          <Nav />
        </div>
        <div className=' '>
          <MyWalletConnect />
        </div>
      </header>
    </div>
  );
}
