import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function Banner() {
  function handleClick() {
    window.location.href = "https://ethdailystaking.versx.io";
  }
  return (
    <div className='cursor-pointer '>
      {" "}
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showIndicators={false}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        onClickItem={handleClick}
      >
        <img
          src='/1.png'
          className='rounded-xl md:rounded-3xl cursor-pointer '
        />
        <img
          src='/2.png'
          className='rounded-xl md:rounded-3xl cursor-pointer '
        />
        <img
          src='/3.png'
          className='rounded-xl md:rounded-3xl cursor-pointer '
        />
        <img
          src='/4.png'
          className='rounded-xl md:rounded-3xl cursor-pointer '
        />
        <img
          src='/5.png'
          className='rounded-xl md:rounded-3xl cursor-pointer '
        />
      </Carousel>
    </div>
  );
}

export default Banner;
