import "../../styles/alertBox.css";

const AlertBox = ({ msg, setError, setErrMsg }) => {
  const handleChange = () => {
    setError(false);
    setErrMsg("");
  };

  return (
    <div className="alert-toast fixed bottom-0 right-0 m-8 w-5/6 md:w-full max-w-sm z-40">
      <input
        type="checkbox"
        className="hidden"
        id="footertoast"
        onClick={handleChange}
      />

      <label
        className="close cursor-pointer flex items-center justify-between w-full p-3 bg-red-500 h-24 rounded shadow-lg text-white relative"
        title="close"
        htmlFor="footertoast"
      >
        {/* Grouped Error Icon and Message */}
        <div className="flex items-center">
          {/* Error Icon */}
          <span className="mr-2 text-xl">⚠️</span>

          {/* Message */}
          {msg}
        </div>

        {/* Close Button */}
        <span
          className="absolute top-2 right-2 cursor-pointer"
          onClick={handleChange}
        >
          <svg
            className="fill-current text-white h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </span>
      </label>
    </div>
  );
};

export default AlertBox;
