import { RouterProvider } from "react-router-dom";
import router from "./router";
import { useEffect, useState } from "react";
import Loader from "./components/Loader.tsx";

import { useBlockChainData } from "./hooks/useLoadBlockchainData";

export default function App() {
  const [dataLoading, setDataLoading] = useState(false);

  useBlockChainData(setDataLoading);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 3000);
  });

  if (!loading) return <Loader />;
  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}
