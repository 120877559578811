const CONFIG = {
  chainId: 1,
  chainName: "homestead",
  chainIdHexa: "0x1",
  contractAddress: "0xDD4eD553dAACeF8490c456ABEFdAE384a8112Fd4", // staking contract that we deploy on remix
  tokenAddress: "0x542f774acACA1a7F9136A789410dc4C3fBB5F6fa", // VERSX token .
  tokenDecimals: "10",
  tokenSymbol: "VERSX",
  tokenName: "VersX",
};

export default CONFIG;
