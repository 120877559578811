import React from "react";

import SidebarItem from "./SidebarItem.tsx";
import { useEffect, useState } from "react";
import { menuItemsData } from "../MenuItemsData.tsx";
import { useLocation } from "react-router-dom";
function Sidebar() {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const closeSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  useEffect(() => {
    closeSidebar();
    console.log("isSidebarOpen", isSidebarOpen);
  }, [location]);
  return (
    <div className='my_sidebar'>
      {isSidebarOpen && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            zIndex: 20,
            width: "100%",
            height: "100%",
          }}
          onClick={() => closeSidebar()}
        ></div>
      )}
      <aside
        style={{
          position: "fixed",
          zIndex: 20,
          backgroundColor: "black",
          borderColor: "black",
          height: "100%",
          top: 0,
          transition: "transform 300ms",
          paddingTop: "5rem", // 20px top padding
          maxWidth: "100%",
          width: "250px",
          transform: isSidebarOpen ? "translateX(0)" : "translateX(0px)",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              padding: "12px 0",
              gap: "10px",
              flexDirection: "column",
              overflowY: "auto",
            }}
          >
            {menuItemsData?.map((item, key) => (
              <SidebarItem key={key} item={item} />
            ))}
          </div>
        </div>
      </aside>
    </div>
  );
}
export default Sidebar;
