import React from "react";

const LockStatusComponent = ({ endDate, period }) => {
  const endDate1 = new Date((parseInt(endDate) + parseInt(period)) * 1000);
  const currentDate = new Date();
  const diff = endDate1.getTime() - currentDate.getTime();

  const lockStatus = diff > 0;

  return (
    <div>
      <div className='px-4 text-center py-2 border-1 border border-vyellow rounded-md font-bold truncate'>
        {" "}
        Status:
        {lockStatus ? (
          <span className='text-left font-bold text-red-400 text-xs'>
            🔒 Locked
          </span>
        ) : (
          <span className='text-left font-bold text-green-500 text-xs'>
            🔓 Unlocked
          </span>
        )}
      </div>
    </div>
  );
};

export default LockStatusComponent;
