import React, { useEffect, useState } from "react";

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;

const TimerComponent = ({ endDate, period }) => {
  const calculateDiff = () => {
    const endDate1 = new Date((parseInt(endDate) + parseInt(period)) * 1000);
    return endDate1.getTime() - new Date().getTime();
  };

  const calculateTimeValues = (diff) => ({
    sec: Math.floor((diff % ONE_MINUTE) / ONE_SECOND),
    min: Math.floor((diff % ONE_HOUR) / ONE_MINUTE),
    hour: Math.floor((diff % ONE_DAY) / ONE_HOUR),
    days: Math.floor(diff / ONE_DAY),
  });

  const [timerValues, setTimerValues] = useState(
    calculateTimeValues(calculateDiff())
  );

  useEffect(() => {
    const myInterval = setInterval(() => {
      const diff = calculateDiff();
      if (diff <= 0) {
        setTimerValues({ sec: 0, min: 0, hour: 0, days: 0 });
      } else {
        setTimerValues(calculateTimeValues(diff));
      }
    }, 1000);

    return () => clearInterval(myInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, period]);

  const { sec, min, hour, days } = timerValues;

  return (
    <div>
      {" "}
      <div className='px-4 text-center py-2 text-white border-1 border-vyellow border rounded-md border-opacity-40 font-bold truncate'>
        Time left:{" "}
        <span className='md:text-base text-sm text-vyellow'>{`${days} D ${hour} H ${min} M ${sec} S`}</span>
      </div>
    </div>
  );
};

export default TimerComponent;
