import { useContext, useState } from "react";
import { ethers } from "ethers";
import classNames from "classnames";
import * as yup from "yup";
import { waitForTransactionReceipt } from "@wagmi/core";
import { useAccount, useWriteContract } from "wagmi";
import { GlobalContext } from "../../context/GlobalContext.js";
import tokenABI from "./../../abi/token.json";
import contractABI from "./../../abi/staking.json";
import LoadingSpinner from "./LoadingSpinner.js";
import { formattedNumberNine } from "../../utils/formatNumber.js";
import { configChain } from "../../index";
import CONFIG from "../../abi/config";

const schema = yup.object().shape({
  amount: yup.number().required(),
});

const StakeForm = ({ setError, setErrMsg, plan }) => {
  const { address } = useAccount();
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [Withdraw, setWithdraw] = useState(false);
  const { blockChainData, fetchAccountData, updateAllowanceProvided } =
    useContext(GlobalContext);

  const { writeContractAsync } = useWriteContract();

  const handleApprove = async () => {
    if (address && Number(amount) <= 0) {
      setError(true);
      setErrMsg("Unable to approve 0 tokens");
    } else if (address) {
      try {
        setLoading(true);
        const MAX_APPROVE_NUMBER = Math.round(15 * 10 ** 19).toString();
        const hash = await writeContractAsync({
          address: CONFIG.tokenAddress,
          abi: tokenABI,
          functionName: "approve",
          args: [
            CONFIG.contractAddress,
            ethers.parseUnits(MAX_APPROVE_NUMBER, Number(CONFIG.tokenDecimals)),
          ],
        });
        await waitForTransactionReceipt(configChain, { hash: hash });
        updateAllowanceProvided(true);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    } else {
      setError(true);
      setErrMsg("Please connect your Web3 Wallet");
    }
  };

  const handleStake = () => {
    schema
      .isValid({
        amount,
      })
      .then(async (value) => {
        if (value && address) {
          if (parseFloat(amount) <= parseFloat(blockChainData.TokenBalance)) {
            try {
              console.log(amount);
              setLoading(true);
              const hash = await writeContractAsync({
                address: CONFIG.contractAddress,
                abi: contractABI,
                functionName: "createStake",
                args: [
                  ethers.parseUnits(
                    amount.toString(),
                    Number(CONFIG.tokenDecimals)
                  ),
                  plan.plan,
                ],
              });

              await waitForTransactionReceipt(configChain, { hash: hash });

              fetchAccountData(address);
            } catch (e) {
              if (e.toString().includes("User rejected the request")) {
                setError(true);
                setErrMsg("User rejected the request");
              }
            } finally {
              setLoading(false);
            }
          } else {
            setError(true);
            setErrMsg("Insufficient Amount");
          }
        } else {
          setError(true);
          setErrMsg(
            value ? "Please connect your wallet" : "Insufficient Value"
          );
        }
      });

    setLoading(false);
  };

  const handleWithdraw = async () => {
    try {
      const value = await schema.isValid({ amount: balance });

      if (value) {
        if (address) {
          const _staking_Balance = (() => {
            switch (plan.plan) {
              case 0:
                return blockChainData.StakeBalance.plan0;
              case 1:
                return blockChainData.StakeBalance.plan1;
              case 2:
                return blockChainData.StakeBalance.plan3;
              case 3:
                return blockChainData.StakeBalance.plan2;
              case 4:
                return blockChainData.StakeBalance.plan4;
              default:
                return 0;
            }
          })();

          if (parseFloat(balance) <= parseFloat(_staking_Balance)) {
            setLoading(true);
            setWithdraw(true);
            const hash = await writeContractAsync({
              address: CONFIG.contractAddress,
              abi: contractABI,
              functionName: "unStake",
              args: [
                ethers.parseUnits(
                  balance.toString(),
                  Number(CONFIG.tokenDecimals)
                ),
                plan.plan,
              ],
            });
            await waitForTransactionReceipt(configChain, { hash: hash });
            fetchAccountData(address);
          } else {
            setError(true);
            setErrMsg("Insufficient Amount");
          }
        } else {
          setError(true);
          setErrMsg("Please connect your wallet");
        }
      }
    } catch (e) {
      if (e.toString().includes("User rejected the request")) {
        setError(true);
        setErrMsg("User rejected the request");
      }
      if (
        e
          .toString()
          .includes("Staking period has not expired. Please wait more !")
      ) {
        setError(true);
        setErrMsg("Staking period has not expired. Please wait more");
      }
    } finally {
      setLoading(false);
      setWithdraw(false);
    }
  };

  return (
    <>
      <div className=''>
        <div>
          <p className='text-xs font-medium  uppercase text-[color:var(--font-color)] text-left '>
            <span className='bg-vyellow rounded-full mr-2 w-5  text-xs font-bold h-5 text-black p-1'>
              02
            </span>{" "}
            Available Balance:{" "}
            {blockChainData.TokenBalance
              ? formattedNumberNine(blockChainData.TokenBalance)
              : "0.0"}{" "}
            {CONFIG.tokenSymbol}
          </p>
          <div className='w-full flex md:flex-row flex-col my-4 gap-6 md:gap-4 items-center justify-between'>
            <div className=' w-full relative'>
              <input
                type='text'
                name='amount'
                className='w-full   bg-vgray  focus:border-opacity-100 border-opacity-20 border-1 border border-vyellow text-white rounded-md v text-md focus:outline-none px-2 py-2'
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <span
                className='absolute top-0 right-0 mr-3 mt-2  text-md cursor-pointer rounded-sm px-2 py-[.7px]  text-vyellow hover:text-white '
                onClick={() => setAmount(blockChainData.TokenBalance)}
              >
                Max
              </span>
            </div>

            {isLoading && !Withdraw ? (
              <LoadingSpinner />
            ) : (
              <>
                <button
                  className={classNames(
                    "mr-3  text-sm cursor-pointer rounded-md  px-2 py-2 h-full w-full md:w-36 text-black hover:text-white hover:bg-vgray border border-1  border-vyellow bg-vyellow",
                    { hidden: blockChainData.AllowanceProvided }
                  )}
                  onClick={handleApprove}
                >
                  Approve
                </button>

                <button
                  className={classNames(
                    "mr-3  text-sm cursor-pointer rounded-md  px-2 py-2 h-full w-full md:w-36 text-black hover:text-white hover:bg-vgray border border-1  border-vyellow bg-vyellow",
                    { hidden: !blockChainData.AllowanceProvided }
                  )}
                  onClick={handleStake}
                >
                  Stake
                </button>
              </>
            )}
          </div>
        </div>
        <div className='my-6'>
          <p className='text-xs  my-4 font-bold uppercase   text-left'>
            Staked Balance:{" "}
            {address
              ? plan.plan === 0
                ? formattedNumberNine(blockChainData.StakeBalance.plan0)
                : plan.plan === 1
                ? formattedNumberNine(blockChainData.StakeBalance.plan1)
                : plan.plan === 2
                ? formattedNumberNine(blockChainData.StakeBalance.plan2)
                : plan.plan === 3
                ? formattedNumberNine(blockChainData.StakeBalance.plan3)
                : plan.plan === 4
                ? formattedNumberNine(blockChainData.StakeBalance.plan4)
                : "0.00"
              : "0.00"}{" "}
            {CONFIG.tokenSymbol}
          </p>

          <div className='w-full flex gap-6 md:gap-4 md:flex-row flex-col items-center justify-between'>
            <div className='w-full relative'>
              <input
                type='text'
                name='amount'
                className='w-full   bg-vgray  focus:border-opacity-100 border-opacity-20 border-1 border border-vyellow text-white rounded-md v text-md focus:outline-none px-2 py-2'
                value={balance}
                onChange={(e) => setBalance(e.target.value)}
              />
              <span
                className='absolute top-0 right-0 mr-3 mt-2  text-md cursor-pointer rounded-sm px-2 py-[.7px]  text-vyellow hover:text-white '
                onClick={() =>
                  setBalance(
                    plan.plan === 0
                      ? blockChainData.StakeBalance.plan0
                      : plan.plan === 1
                      ? blockChainData.StakeBalance.plan1
                      : plan.plan === 2
                      ? blockChainData.StakeBalance.plan2
                      : plan.plan === 3
                      ? blockChainData.StakeBalance.plan3
                      : plan.plan === 4
                      ? blockChainData.StakeBalance.plan4
                      : 0
                  )
                }
              >
                Max
              </span>
            </div>

            {isLoading && Withdraw ? (
              <LoadingSpinner />
            ) : (
              <button
                className='mr-3  text-sm cursor-pointer rounded-md  px-2 py-2 h-full w-full md:w-36 text-black hover:text-white hover:bg-vgray border border-1  border-vyellow bg-vyellow'
                onClick={handleWithdraw}
              >
                Withdraw
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StakeForm;
