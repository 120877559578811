import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalContext.js";
import { ConnectKitButton } from "connectkit";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
const Header = () => {
  const { address, isDisconnected } = useAccount();

  const {
    updateStakedBalance,
    updateRewardBalance,
    updateTokenBalance,
    fetchAccountData,
  } = useContext(GlobalContext);

  const [isOpen, setIsOpen] = useState(false);
  const { open } = useWeb3Modal();
  const disconnectWallet = () => {
    updateStakedBalance({
      plan0: null,
      plan1: null,
      plan2: null,
      plan3: null,
      plan4: null,
    });
    updateRewardBalance({
      plan0: null,
      plan1: null,
      plan2: null,
      plan3: null,
      plan4: null,
    });
    updateTokenBalance(null);
  };

  useEffect(() => {
    if (isDisconnected) {
      disconnectWallet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisconnected]);

  useEffect(() => {
    if (address) {
      fetchAccountData(address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisconnected, address]);

  const ConnectButton = () => {
    return (
      <ConnectKitButton.Custom>
        {({ isConnected, show, truncatedAddress }) => {
          return (
            <div onClick={show}>
              {isConnected ? (
                <button
                  onClick={() => open()}
                  className=' bg-vgray border-1 border border-vyellow py-2 md:my-0 my-1  text-white   px-4 md:px-[29px] rounded-md text-xs    '
                >
                  <span className='    '>
                    {address?.slice(0, 6)}...{address?.slice(-4)}
                  </span>
                </button>
              ) : (
                <p className='cursor-pointer bg-vgray border-1 border border-vyellow py-2 md:my-0 my-1  text-white   px-4 md:px-11 rounded-md text-xs md:text-sm   '>
                  Connect
                </p>
              )}
            </div>
          );
        }}
      </ConnectKitButton.Custom>
    );
  };
  return (
    <div className=' px-4 md:px-8  mx-auto  '>
      <ConnectButton />
    </div>
  );
};

export default Header;
