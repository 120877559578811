export const AppReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_TOKEN_BALANCE":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          TokenBalance: action.payload,
        },
      };
    case "UPDATE_STAKED_BALANCE":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          StakeBalance: action.payload,
        },
      };

    case "UPDATE_STAKE_TIME":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          StakedTime: action.payload,
        },
      };
    case "UPDATE_ALLOWANCE_PROVIDED":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          AllowanceProvided: action.payload,
        },
      };

    case "UPDATE_REWARDS_BALANCE":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          RewardBalance: action.payload,
        },
      };

    case "UPDATE_TOKEN_PRICE":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          TokenPrice: action.payload,
        },
      };

    case "UPDATE_TOTAL_REWARDS":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          TotalRewards: action.payload,
        },
      };

    case "UPDATE_TOTAL_STAKED":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          TotalStaked: action.payload,
        },
      };
    case "UPDATE_TOTAL_STAKED_PER_ACCOUNT":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          TotalStakedPerAccount: action.payload,
        },
      };
    case "UPDATE_APY":
      return {
        ...state,
        blockChainData: {
          ...state.blockChainData,
          apy: action.payload,
        },
      };
    default:
      return state;
  }
};
