import { ethers } from "ethers";
import { createContext, useReducer } from "react";
import CONFIG from "../abi/config";
import { AppReducer } from "./AppReducer.js";
import stakeABI from "./../abi/staking.json";
import tokenABI from "./../abi/token.json";
import { readContract } from '@wagmi/core'
import { configChain } from '../index'

const initialState = {
  blockChainData: {
    TokenBalance: 0,
    StakeBalance: {
      plan0: 0,
      plan1: 0,
      plan2: 0,
      plan3: 0,
      plan4: 0,
    },
    RewardBalance: {
      plan0: 0,
      plan1: 0,
      plan2: 0,
      plan3: 0,
      plan4: 0,
    },
    TotalStakedPerAccount: {
      plan0: 0,
      plan1: 0,
      plan2: 0,
      plan3: 0,
      plan4: 0,
    },
    StakedTime: {
      plan0: 0,
      plan1: 0,
      plan2: 0,
      plan3: 0,
      plan4: 0,
    },
    AllowanceProvided: false,
    TokenPrice: 0,
    TotalRewards: 0,
    TotalStaked: 0,
    apy: {
      one_month_apy: 0,
      two_month_apy: 0,
      three_month_apy: 0,
      six_month_apy: 0,
      one_year_apy: 0,
    },
  },
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const updateTokenBalance = (balance) => {
    dispatch({
      type: "UPDATE_TOKEN_BALANCE",
      payload: balance,
    });
  };

  const updateStakedBalance = (balance) => {
    dispatch({
      type: "UPDATE_STAKED_BALANCE",
      payload: balance,
    });
  };

  const updateRewardBalance = (rewards) => {
    dispatch({
      type: "UPDATE_REWARDS_BALANCE",
      payload: rewards,
    });
  };

  const updateStakeTime = (unixTime) => {
    dispatch({
      type: "UPDATE_STAKE_TIME",
      payload: unixTime,
    });
  };
  const updateTokenPrice = (price) => {
    dispatch({
      type: "UPDATE_TOKEN_PRICE",
      payload: price,
    });
  };

  const updateTotalRewards = (rewards) => {
    dispatch({
      type: "UPDATE_TOTAL_REWARDS",
      payload: rewards,
    });
  };

  const updateAllowanceProvided = (allow) => {
    dispatch({
      type: "UPDATE_ALLOWANCE_PROVIDED",
      payload: allow,
    });
  };

  const updateTotalStaked = (totalStacked) => {
    dispatch({
      type: "UPDATE_TOTAL_STAKED",
      payload: totalStacked,
    });
  };

  const updateTotalStakedPerAccount = (totalStakedPerAccount) => {
    dispatch({
      type: "UPDATE_TOTAL_STAKED_PER_ACCOUNT",
      payload: totalStakedPerAccount,
    });
  };

  const updateApy = (apy) => {
    dispatch({
      type: "UPDATE_APY",
      payload: apy,
    });
  };

  const fetchAccountData = async (address) => {

    if (!address) return;

    const func1Param = ['getDailyRewards', 'getTotalRewardsPerStakerAtEnd'];
    const func2Param = ['stakeOf', 'stakes'];

    const result1Param = await Promise.all(func1Param.map(fn => {
      const promises = [];
      for (let i = 0; i < 5; i++) {
        promises.push(readContract(configChain, {
          address: CONFIG.contractAddress,
          abi: stakeABI,
          functionName: fn,
          args: [i],
          account: address
        }));
      }
      return Promise.all(promises);
    }));

    const result2Param = await Promise.all(func2Param.map(fn => {
      const promises = [];
      for (let i = 0; i < 5; i++) {
        promises.push(readContract(configChain, {
          address: CONFIG.contractAddress,
          abi: stakeABI,
          functionName: fn,
          args: [address, i]
        }));
      }
      return Promise.all(promises);
    }));

    const [rewardBalances, totalTokensPerAccount] = result1Param;
    const [stakeBalances, stakeTimes] = result2Param;

    const totalStake = await readContract(configChain, {
      address: CONFIG.contractAddress,
      abi: stakeABI,
      functionName: 'totalStake',
    });

    const totalReward = await readContract(configChain, {
      address: CONFIG.contractAddress,
      abi: stakeABI,
      functionName: 'totalRewards',
    });


    updateTotalRewards(
      ethers.formatUnits(totalReward, Number(CONFIG.tokenDecimals))
    );
    updateTotalStaked(
      ethers.formatUnits(totalStake, Number(CONFIG.tokenDecimals))
    );

    const stakedBalances = stakeBalances.map((balance) =>
      ethers.formatUnits(balance, Number(CONFIG.tokenDecimals))
    );

    updateStakedBalance({
      plan0: stakedBalances[0],
      plan1: stakedBalances[1],
      plan2: stakedBalances[2],
      plan3: stakedBalances[3],
      plan4: stakedBalances[4],
    });

    updateTotalStakedPerAccount({
      plan0: totalTokensPerAccount[0],
      plan1: totalTokensPerAccount[1],
      plan2: totalTokensPerAccount[2],
      plan3: totalTokensPerAccount[3],
      plan4: totalTokensPerAccount[4],
    });

    const rewardBalancesFormatted = rewardBalances.map((balance) =>
      ethers.formatUnits(balance, Number(CONFIG.tokenDecimals))
    );

    updateRewardBalance({
      plan0: rewardBalancesFormatted[0],
      plan1: rewardBalancesFormatted[1],
      plan2: rewardBalancesFormatted[2],
      plan3: rewardBalancesFormatted[3],
      plan4: rewardBalancesFormatted[4],
    });

    const stakeTimesTimestamps = stakeTimes.map(
      (stakeTime) => {
        return stakeTime[2];
      }
    );

    updateStakeTime({
      plan0: stakeTimesTimestamps[0],
      plan1: stakeTimesTimestamps[1],
      plan2: stakeTimesTimestamps[2],
      plan3: stakeTimesTimestamps[3],
      plan4: stakeTimesTimestamps[4],
    });

    const balanceOf = await readContract(configChain, {
      address: CONFIG.tokenAddress,
      abi: tokenABI,
      functionName: 'balanceOf',
      args: [address]
    });

    updateTokenBalance(
      ethers.formatUnits(balanceOf, Number(CONFIG.tokenDecimals))
    );

    const allowanceProvided = await readContract(configChain, {
      address: CONFIG.tokenAddress,
      abi: tokenABI,
      functionName: 'allowance',
      args: [address, CONFIG.contractAddress]
    });
    updateAllowanceProvided(allowanceProvided > 0);
  };

  return (
    <GlobalContext.Provider
      value={{
        ...state,
        updateTokenBalance,
        updateStakedBalance,
        updateStakeTime,
        updateAllowanceProvided,
        updateRewardBalance,
        updateTokenPrice,
        updateTotalRewards,
        updateTotalStaked,
        updateTotalStakedPerAccount,
        updateApy,
        fetchAccountData,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
