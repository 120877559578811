import { ethers } from "ethers";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../context/GlobalContext.js";
import stakeABI from "../abi/staking.json";
import CONFIG from "../abi/config.js";

export const useBlockChainData = (setDataLoading) => {
  const { updateTotalRewards, updateTotalStaked, updateApy } =
    useContext(GlobalContext);

  const reloadData = async () => {
    setDataLoading(true);

    const signer = new ethers.InfuraProvider(
      CONFIG.chainName,
      process.env.REACT_APP_INFURA_API_KEY
    );

    const contract = new ethers.Contract(
      CONFIG.contractAddress,
      stakeABI,
      signer
    );

    const results = await Promise.all([
      contract.totalStake(),
      contract.totalRewards(),
      contract.apr("0"),
      contract.apr("1"),
      contract.apr("2"),
      contract.apr("3"),
      contract.apr("4"),
    ]);

    const [totalStake, totalRewards, apr0, apr1, apr2, apr3, apr4] = results;

    updateTotalRewards(
      ethers.formatUnits(totalRewards.toString(), Number(CONFIG.tokenDecimals))
    );
    updateTotalStaked(
      ethers.formatUnits(totalStake.toString(), Number(CONFIG.tokenDecimals))
    );

    updateApy({
      one_month_apy: apr0.toString(),
      two_month_apy: apr1.toString(),
      three_month_apy: apr2.toString(),
      six_month_apy: apr3.toString(),
      one_year_apy: apr4.toString(),
    });

    setDataLoading(false);
  };

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
